import Service from './Services.js';
export default {
    login(data){        
        return Service.post('loginRaffles',data);
    },
   validateToken(config){
        return Service.get('validateToken',config);
   },
   logout(config){
        return Service.get('logout',config);
   }
}